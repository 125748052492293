export default [
    {
        "id": 1,
        "name": "Umeko Greenwell",
        "roll": "2022msctexas001",
        "email": "harsha@gmail.com",
        "role": "cordinator",
        "address": "744 Schmedeman Center",
        "password": "harsha"
    }
]